<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined class="pt-2 pb-3">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-circle"
                                    clearable
                                    solo
                                    v-model="sales"
                                    :items="saless"
                                    :disabled="salesDisabled"
                                    item-value="salesman_id"
                                    item-text="salesman_name"
                                    label="Sales Person"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    clearable
                                    solo
                                    v-model="customer"
                                    :items="customers"
                                    item-value="cust_id"
                                    item-text="cust_name"
                                    label="Customer"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-clipboard-text-multiple"
                                    solo
                                    v-model="order_id"
                                    :loading="loadingOrder"
                                    :items="items"
                                    item-value="order_id"
                                    item-text="order_id"
                                    :search-input.sync="search"
                                    cache-items
                                    hide-no-data
                                    label="Sales Contract"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-border-vertical"
                                    clearable
                                    solo
                                    v-model="outstanding"
                                    :items="outstandings"
                                    item-value="value"
                                    item-text="label"
                                    label="Outstandings Order"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    dense
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-circle-small"
                                    clearable
                                    solo
                                    dense
                                    v-model="status"
                                    :items="statuses"
                                    item-value="value"
                                    item-text="text"
                                    label="Status"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date Cust Aprv From"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="3" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date Cust Aprv to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="border-12" 
                                color="info" elevation="2" style="padding: 18px; padding-top: 20px; padding-bottom: 20px;" @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="6" md="3">
                <v-card outlined class="p-4 elevation-1">  
                    <h6 class="cyan-text text-darken-3 m-0">Grand Summary Order</h6>
                    <div id="container1" style="height: 300px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
                <v-card outlined class="p-4 elevation-1">  
                    <h6 class="cyan-text text-darken-3 m-0">Grand Summary</h6>
                    <div id="container2" style="height: 300px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
                <v-card outlined>  
                    <v-data-table 
                    fixed-header
                    dense
                    height="235"
                    :headers="headerSummary" 
                    :items="summaries" 
                    class="elevation-1 mt-2" 
                    :item-class="tr_datatable"
                    :items-per-page="-1"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    :search="searchSummary" 
                    :loading="loading">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <div class="d-flex w-100">
                                    <h6 class="cyan-text text-darken-3 m-0">Summary Product</h6>
                                    <v-spacer></v-spacer>
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchSummary" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 text-white border-12"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcelSummary"
                                            :fields="headersColumnSummary"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.wgt_ord`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                        </template>
                        <template v-slot:[`item.wgt_shipped`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                        </template>
                        <template v-slot:[`item.wgt_outstanding`]="{ item }">
                            {{ $store.getters.convertToCurrency(item.wgt_outstanding) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="elevation-2 rounded-l" outlined>
                    <v-card-text class="mt-5">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="sales_contracts"
                            :loading="loading"
                            loading-text="Please wait, retrieving data"
                            :items-per-page="20"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            class="elevation-1"
                            fixed-header
                            height="500"
                            :search="searchSc"
                            :item-class="tr_datatable"
                        >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-100">
                                        <h6 class="cyan-text text-darken-3 m-0">Result</h6>
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchSc" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 text-white border-12"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcel"
                                                :fields="headersColumn"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                            </download-excel>
                                        </v-btn>
                                        
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.dt_cust_aprv`]="{ item }">
                                <span style="font-size: 12px;" class="btn btn-sm" :class="item.dt_cust_aprv === '01 Jan 1900' ? 'btn-danger' : ''">{{ item.dt_cust_aprv === '01 Jan 1900' ? 'Belum diapprove' : item.dt_cust_aprv }}</span>
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                            mdi-eye
                                        </v-icon>
                                    </template>
                                    <span>Show</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog v-model="dialog" max-width="1200px">                
                    <v-card>
                        <v-card-title>Detail SC</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table :headers="header_po_items" :items="po_items" class="elevation-1" page-count="5" :search="searchDetail" :loading="loading3">
                                            <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="d-flex w-25">
                                                        <v-text-field v-model="searchDetail" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                                            </template>
                                            <template v-slot:[`item.wgt_rsv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_rsv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_ppp`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_ppp) }}
                                            </template>
                                            <template v-slot:[`item.wgt_deliv`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_deliv) }}
                                            </template>
                                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                                            </template>
                                            <template v-slot:[`item.wgt_stock`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_stock) }}
                                            </template>
                                            <template v-slot:[`item.wgt_plan`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_plan) }}
                                            </template>
                                            <template v-slot:[`item.wgt_outstanding`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt_outstanding) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Sales Contract Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [],
            customer: '',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            outstandings: [
                {
                    value: 'Y',
                    label: 'Yes'
                },
                {
                    value: 'N',
                    label: 'No'
                }
            ],
            outstanding: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            headers: [
                { text: 'SC Apprv Date', value: 'dt_cust_aprv' },
                { text: 'SC Number', value: 'order_id' },
                { text: 'Item Num', value: 'stat' },
                { text: 'ProdCode', value: 'prod_code' },
                { text: 'Descr', value: 'descr' },
                { text: 'Customer', value: 'cust_name' },
                { text: 'Sales', value: 'salesman_name' },
                { text: 'Schedule Date', value: 'tgl_plan' },
                { text: 'Action', value: 'action' }
            ],
            headersColumn:{
                'SC Order Date': 'dt_order',
                'SC Apprv Date': 'dt_cust_aprv',
                'SC Number': 'order_id',
                'Item Num': 'stat',
                'ProdCode': 'prod_code',
                'Descr': 'descr',
                'Customer': 'cust_name',
                'Sales': 'salesman_name',
                'Schedule Date': 'tgl_plan',
                'Wgt Order': 'wgt_ord',
                'Wgt Reserved': 'wgt_rsv',
                'Wgt PPP': 'wgt_ppp',
                'Wgt Delivery': 'wgt_deliv',
                'Wgt Shipped': 'wgt_shipped',
                'Wgt Stocked': 'wgt_stock',
                'Wgt Planned': 'wgt_plan',
                'Wgt Outstanding': 'wgt_outstanding'
            },
            sales_contracts: [],
            options: {},
            totalItems: 10,
            pagination: 1, 
            searchItem: '',
            po_item: {},
            po_items: [],
            dialog: false,
            pagination_item: 1,
            loading3: false,
            totalItem_po_items: 10,
            option_po_items: {},
            searchSc: '',
            header_po_items: [
                { text: 'Item', value: 'item_num' },
                { text: 'Brand', value: 'brand_id' },
                { text: 'Descr', value: 'descr' },
                { text: 'Req Week', value: 'req_ship_week' },
                { text: 'Order (Kg)', value: 'wgt_ord', align:'right' },
                { text: 'Receive (Kg)', value: 'wgt_rsv', align:'right' },
                { text: 'PPP (Kg)', value: 'wgt_ppp', align:'right' },
                { text: 'Delivery (Kg)', value: 'wgt_deliv', align:'right' },
                { text: 'Ship (Kg)', value: 'wgt_shipped', align:'right' },
                { text: 'Stock (Kg)', value: 'wgt_stock', align:'right' },
                { text: 'Plan (Kg)', value: 'wgt_plan', align:'right' },
                { text: 'Outstanding (Kg)', value: 'wgt_outstanding', align:'right' }
            ],
            searchDetail: '',
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            group: '',
            salesDisabled: true,
            searchSummary: '',
            summaries: [],
            headerSummary: [
                { text: 'Prodcode', value: 'prod_code', align:'left' },
                { text: 'Product', value: 'descr', align:'left' },
                { text: 'Order (Kg)', value: 'wgt_ord', align:'right' },
                { text: 'Shipped (Kg)', value: 'wgt_shipped', align:'right' },
                { text: 'Outstanding (Kg)', value: 'wgt_outstanding', align:'right' }
            ],
            headersColumnSummary:{
                'Prodcode': 'prod_code',
                'Product': 'descr',
                'Order (Kg)': 'wgt_ord',
                'Shipped (Kg)': 'wgt_shipped',
                'Outstanding (Kg)': 'wgt_outstanding',
            },
            searchInput: '',
            x: window.matchMedia("(max-width: 991px)"),
            brands: [],
            brand: '',
            statuses: [
                {text: 'Open', value: 'O'},
                {text: 'Closed', value: 'C'}
            ],
            status: 'O',
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getSales()
        await this.getCustomer()
        await this.getGroupUser()
        await this.getBrand()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {

                            this.salesid = res.data.data[i]['var_value']
                            this.sales = res.data.data[i]['var_value']
                            this.getSales()
                            this.getCustomer()
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'CUSTID') {
                        this.customer = res.data.data[i]['var_value']
                        this.getCustomer()
                    }
                }
                // this.submit(1,10)

            })
        },
        async getGroupUser(){
            await axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=SR&appl_id=WEBSR`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.group = res.data.data
                this.getEnvConf()

            });
        },
        close(){
            this.dialog = false
        },
        searchItems(){

        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getSales(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/sales?salesid=${this.salesid ? this.salesid : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                // this.saless = res.data

                if(res.data.length == 1){
                    this.salesDisabled = true
                    this.saless = res.data
                } else {
                    this.salesDisabled = false
                    this.saless = res.data
                }

            });
        },
        async getCustomer(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/CustOrder?groupid=${this.group}&txtSalesman=${this.sales}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.customers = res.data
            });
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/sales-contract?search=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        async submit(page = 1, itemsPerPage = 10){
            this.loading = true
            this.searchInput = true
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            await this.summary()
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/order_report?txtSalesman=${this.sales ? this.sales : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}&txtBrand=${this.brand ? this.brand : ''}&txtStatus=${this.status ? this.status : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.sales_contracts = res.data
            })
        },
        async summary(){
            var reqBody = {
                'txtSalesman': this.sales ? this.sales : '',
                'txtCustomer': this.customer ? this.customer : '',
                'txtOrderID': this.order_id ? this.order_id : '',
                'txtOutstanding': this.outstanding ? this.outstanding : '',
                'txtStart': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'txtEnd': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'txtBrand': this.brand ? this.brand : '',
                'txtStatus' : this.status ? this.status : ''
            }
            const respData = await backendApi.fetchCore('/api/sr/order_report/summary', reqBody, false, false, false)
            if (respData.status === 200) {
                this.getChart(respData.data.grand_summary, 'container1')
                this.getChartSummary(respData.data.grand_summary, 'container2')
                this.summaries = respData.data.summary
            }
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail()
        },
        async getPullDataDetail(){
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/order_report/show?txtOrderID=${this.po_item.order_id}&item_num=${this.po_item.stat}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.po_items = res.data
                this.loading3 = false
            })
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChart(data, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {       
                        type: "pie",
                        showInLegend: true,
                        toolTipContent: "{y}",
                        legendText: "{indexLabel} ({y})",
                        dataPoints: [
                            {  y: parseFloat(data.wgt_ord), indexLabel: "Order", color: '#039BE5' }
                        ]
                    }
                ]
            });

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getChartSummary(data, container){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {       
                        type: "pie",
                        showInLegend: true,
                        toolTipContent: "{y}",
                        legendText: "{indexLabel} ({y})",
                        dataPoints: [
                            {  y: parseFloat(data.wgt_outstanding), indexLabel: "Outstandings", color: "#EF5350" },
                            {  y: parseFloat(data.wgt_shipped), indexLabel: "Shipped", color: "#4CAF50" },
                        ]
                    }
                ]
            });

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async exportExcel(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            const respData = await backendApi.fetchCore(`/api/sr/order_report?txtSalesman=${this.sales ? this.sales : ''}&txtCustomer=${this.customer ? this.customer : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date ? start_date : ""}&txtEnd=${end_date ? end_date : ""}&txtBrand=${this.brand ? this.brand : ''}&txtStatus=${this.status ? this.status : ''}`, null, false, false, false)

            if (respData.status === 200) {
                return respData.data
            }
        },
        async exportExcelSummary(){
            var reqBody = {
                'txtSalesman': this.sales ? this.sales : '',
                'txtCustomer': this.customer ? this.customer : '',
                'txtOrderID': this.order_id ? this.order_id : '',
                'txtOutstanding': this.outstanding ? this.outstanding : '',
                'txtStart': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'txtEnd': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'txtBrand': this.brand ? this.brand : '',
                'txtStatus' : this.status ? this.status : ''
            }
            const respData = await backendApi.fetchCore('/api/sr/order_report/summary', reqBody, false, false, false)
            if (respData.status === 200) {
                return respData.data.summary
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch: {
        search (val) {
            val && val !== this.order_id && this.querySelections(val)
        },
    }
}
</script>
<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>